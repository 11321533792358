import { useCallback } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import BannerSrc from '@/assets/signin.png';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useCheckoutSession } from '@/services/api/checkout';

import {
  Body,
  Card,
  CardImage,
  ContactWrapper,
  Divider,
  Footer,
  Header,
  ScrollArea,
  ScrollAreaContent,
  StyledPage,
  Wordmark,
} from './SubscriptionRenewalSucceededPage.styles';

function InternalSubscriptionRenewalSucceededPage() {
  const { intercomService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get('checkout-session-id') ?? null;
  const checkoutSession = useCheckoutSession(checkoutSessionId);

  const handleContinue = useCallback(() => {
    navigate('/');
  }, [navigate]);

  if (checkoutSessionId === null) {
    return <Navigate to="/" />;
  }

  if (!checkoutSession.data) {
    return <PageLoader />;
  }

  return (
    <StyledPage>
      <Header>
        <Wordmark />
      </Header>
      <Divider />
      <Body>
        <ScrollArea>
          <ScrollAreaContent>
            <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
              Thanks for your renewal!
            </RMText>
            <RMSpacer spacing="lg" direction="column" />
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-tertiary">
              Order number #<b>{checkoutSession.data.invoiceNumber}</b>
            </RMText>
            <Card>
              <CardImage src={BannerSrc} />
              <RMSpacer spacing="md" direction="column" />
              <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
                Cheers to more stories!
              </RMText>
              <RMSpacer spacing="md" direction="column" />
              <RMText type="sans" size="s" color="on-surface-primary" lineHeight="s">
                Congrats on extending your subscription to capture more stories with Remento.
              </RMText>
              {isMobile === false && (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <RMButton background="primary" onClick={handleContinue} fullWidth autoLoading>
                    Return to Remento
                  </RMButton>
                </>
              )}
            </Card>
            <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
            <ContactWrapper>
              <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
                Questions?
              </RMText>
              <button onClick={() => intercomService?.setChatDisplayed(true)}>
                <RMText type="sans" size={isMobile ? 'xs' : 's'} underline color="primary">
                  Contact Support
                </RMText>
              </button>
            </ContactWrapper>
          </ScrollAreaContent>
        </ScrollArea>

        {isMobile && (
          <Footer>
            <RMButton background="primary" onClick={handleContinue} fullWidth autoLoading>
              Return to Remento
            </RMButton>
          </Footer>
        )}
      </Body>
    </StyledPage>
  );
}

export function SubscriptionRenewalSucceededPage() {
  return (
    <RementoPage type="empty">
      <InternalSubscriptionRenewalSucceededPage />
    </RementoPage>
  );
}
