import { styled } from '@linaria/react';

export const FaqListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
`;

export const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const FaqListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

export const FaqAdditionalTextWrapper = styled.div`
  text-align: center;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: var(--on-surface-primary);
`;
