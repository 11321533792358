import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';

import {
  Body,
  Divider,
  Footer,
  Header,
  ScrollArea,
  ScrollAreaContent,
  StyledPage,
  Wordmark,
} from './SubscriptionCancelledSurveyPage.styles';

const SURVEY_OPTIONS = [
  { label: 'I am done with my book', value: 'done-with-book' },
  { label: 'I didn’t use Remento', value: 'did-not-use' },
  { label: 'It’s too expensive', value: 'too-expensive' },
  { label: 'Too hard to use', value: 'hard-to-use' },
  { label: 'I’m using a different product to record stories', value: 'using-different-product' },
  { label: 'I had technical issues', value: 'technical-issues' },
  { label: 'Something else', value: 'something-else' },
];

function InternalSubscriptionCancelledSurveyPage() {
  const { surveyAnalyticsService } = useServices();
  const isMobile = useIsMobileViewport();
  const navigate = useNavigate();

  const user = useUser();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleContinue = useCallback(() => {
    if (selectedOption == null) {
      return;
    }
    surveyAnalyticsService.onSubscriptionCancelledSurveySubmitted(selectedOption);
    navigate('/');
    toast('Your subscription will not auto-renew', 'root-toast', 'error');
  }, [navigate, selectedOption, surveyAnalyticsService]);

  return (
    <StyledPage>
      <Header>
        <Wordmark />
      </Header>
      <Divider />

      <Body>
        <ScrollArea>
          <ScrollAreaContent>
            <RMText type="serif" size="xl" color="on-surface-primary" align="center">
              Your subscription has been cancelled
            </RMText>
            <RMSpacer spacing={isMobile ? 'xs' : 'sm'} direction="column" />
            <RMText type="sans" size="s" color="on-surface-primary" align="center">
              You will still have access to Remento through {dayjs(user?.subscription?.endsOn).format('MMMM, D YYYY')}.
              <br />
              <br />
              Let us know why it was time to say goodbye.
            </RMText>
            <RMSpacer spacing={isMobile ? 'lg' : '2xl'} direction="column" />
            <RMPicker.Root>
              {SURVEY_OPTIONS.map((option) => (
                <RMPicker.Item
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  selected={selectedOption === option.value}
                  textColor="on-surface-primary"
                  bold="medium"
                  onClick={() => {
                    setSelectedOption((currentOption) => (currentOption === option.value ? null : option.value));
                  }}
                />
              ))}
            </RMPicker.Root>
            <RMSpacer spacing="2xl" direction="column" />
            {isMobile == false && (
              <RMButton background="primary" onClick={handleContinue} fullWidth disabled={selectedOption === null}>
                Continue
              </RMButton>
            )}
          </ScrollAreaContent>
        </ScrollArea>

        {isMobile && (
          <Footer>
            <RMButton background="primary" onClick={handleContinue} fullWidth disabled={selectedOption === null}>
              Continue
            </RMButton>
          </Footer>
        )}
      </Body>
    </StyledPage>
  );
}

export function SubscriptionCancelledSurveyPage() {
  return (
    <RementoPage type="empty">
      <InternalSubscriptionCancelledSurveyPage />
    </RementoPage>
  );
}
