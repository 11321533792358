import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const PlansWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  gap: var(--spacing-3xl);

  body[data-mobile='true'] & {
    flex-direction: column-reverse;
  }
`;

export const PlanCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  max-width: 337px;

  body[data-mobile='true'] & {
    max-width: 100%;
  }
`;

export const PlanCardBanner = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--color-aqua-90);
  padding: var(--spacing-sm) var(--spacing-md);
  border-top-left-radius: var(--radius-round);
  border-top-right-radius: var(--radius-round);
`;

export const PlanCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--surface-dim);
  border: 1px solid var(--border-hairline);
  border-radius: var(--radius-round);
  width: 100%;
  height: 326px;
  padding: 0 var(--spacing-xl) var(--spacing-xl);

  &[data-banner='true'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  body[data-mobile='true'] & {
    height: 263px;
  }
`;

export const PlanCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding-bottom: var(--spacing-xl);
  flex: 1;
`;

export const PlanCardTitle = styled(RMText)`
  body[data-mobile='true'] & {
    font-size: 1.3125rem;
  }
`;

export const PlanCardPriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: var(--spacing-xs);
`;

export const PlanCardPriceText = styled(RMText)`
  font-size: 4.0625rem;
  line-height: 4.0625rem;

  body[data-mobile='true'] & {
    font-size: 2.625rem;
    line-height: 2.625rem;
  }
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2xl);

  body[data-mobile='true'] & {
    flex-direction: column;
  }
`;

export const FeaturesImage = styled.img`
  border-radius: var(--radius-round);
  max-width: 50%;
  max-height: 366px;
  object-fit: cover;

  body[data-mobile='true'] & {
    max-width: 100%;
    width: 100%;
  }
`;

export const FeaturesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  body[data-mobile='false'] & {
    padding-top: var(--spacing-md);
  }
`;

export const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding-left: var(--spacing-sm);
`;

export const FeaturesListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
`;

export const FeaturesListItemIcon = styled(FontAwesomeIcon)`
  margin-top: var(--spacing-2xs);
  color: var(--on-surface-primary);
`;
