export interface FaqItem {
  question: string;
  answer: string;
}

export const FAQ_ITEMS: Array<FaqItem> = [
  {
    question: 'Why should I renew Remento? ',
    answer: `If you're still working on your book or want to start new book projects, you need to renew your Remento subscription to continue telling new stories.`,
  },
  {
    question: 'What happens if I choose not to renew my subscription?',
    answer: `If you don't renew, you'll have view-only access to all projects associated with your subscription. You can still order books with existing stories, but you won't be able to edit stories, record new ones, or start new projects.`,
  },
  {
    question: 'Will my QR codes in my book work if I cancel my Remento subscription? ',
    answer: `Yes, you will still be able to access your original recordings when you scan the QR codes in your printed Remento book.`,
  },
  {
    question: 'Can I still order more books and send copies to family and friends?',
    answer: `Yes, you can order copies of your book anytime. `,
  },
  {
    question: 'What are my renewal options?',
    answer: `You can renew your subscription for $99/year or $12/month, regardless of how many Storytellers you've added. `,
  },
  {
    question: 'If I cancel my subscription, when do I lose access to features?',
    answer: `You retain full access to all features until the end of your paid subscription period, even if you cancel before that date.`,
  },
  {
    question: 'Can I resubscribe after canceling?',
    answer: `Yes, you can resubscribe at any time to regain full access to all features. Your new subscription will start on the day of your renewal.`,
  },
  {
    question: 'Will I be charged a renewal fee for multiple subscriptions if I purchased Remento for multiple people?',
    answer: `No, you will only be charged one renewal fee no matter how many Storytellers you originally purchased for. `,
  },
  {
    question: 'What does it cost to start new projects for an existing Storyteller?',
    answer: `Nothing! It’s free to start new projects for an existing Remento Storyteller.`,
  },
  {
    question: 'How much do additional copies cost?',
    answer: `Additional copies of books that are 200 pages and fewer are $69, and books over 200 pages are $99 each. `,
  },
];
