import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  padding: 0 var(--spacing-3xl);
  height: 5rem;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-lg);
    height: 3.5rem;
  }
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
`;

export const ScrollArea = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export const ScrollAreaContent = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 600px;
  margin: 0 auto;
  padding: var(--spacing-2xl) var(--spacing-3xl);

  body[data-mobile='true'] & {
    max-width: 100%;
    padding: var(--spacing-md);
  }
`;

export const Card = styled.div`
  margin-top: var(--spacing-2xl);
  width: 100%;

  body[data-mobile='false'] & {
    max-width: 510px;
    border-radius: var(--radius-round);
    background-color: var(--surface-bright);
    box-shadow: var(--elevation-bottom-3);
    padding: var(--spacing-xl);
  }
`;

export const CardImage = styled.img`
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  opacity: 0.9;
  border-radius: var(--radius-minimal);
  object-fit: cover;
  object-position: 50% 30%;
  aspect-ratio: 1.7;
`;

export const ContactWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2xs);
  align-items: center;
  justify-content: center;
  width: 100%;

  body[data-mobile='true'] & {
    justify-content: flex-start;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--surface);
  border-top: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-top-2);
  width: 100%;
  margin-top: auto;
`;
