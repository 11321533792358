import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { FAQ_ITEMS, FaqItem } from './subscription-faq-items';
import { FaqAdditionalTextWrapper, FaqList, FaqListItem, FaqListWrapper, Link } from './SubscriptionFaqList.styles';

export interface SubscriptionFaqListProps {
  items?: Array<FaqItem>;
}

export function SubscriptionFaqList({ items = FAQ_ITEMS }: SubscriptionFaqListProps) {
  const isMobile = useIsMobileViewport();

  return (
    <FaqListWrapper>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Frequently asked questions
      </RMText>

      <FaqList>
        {items.map((item) => (
          <FaqListItem key={item.question}>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              {item.question}
            </RMText>
            <RMText type="sans" size="s" color="on-surface-primary">
              {item.answer}
            </RMText>
          </FaqListItem>
        ))}
      </FaqList>

      <FaqAdditionalTextWrapper>
        <RMText type="sans" size="s" color="on-surface-primary" align="center">
          <b>Have additional questions?</b>
          <br />
          <Link href="https://help.remento.co" target="_blank">
            <RMText type="sans" size="s" color="on-surface-primary">
              View our complete FAQ
            </RMText>{' '}
          </Link>
          or send us a note at{' '}
          <Link href="mailto:support@remento.co" target="_blank">
            <RMText type="sans" size="s" color="on-surface-primary">
              support@remento.co
            </RMText>
          </Link>
          .
        </RMText>
      </FaqAdditionalTextWrapper>
    </FaqListWrapper>
  );
}
